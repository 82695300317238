@import url(https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);


* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    font-family: 'Poppins', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    overflow-x: hidden;
    overflow-y: auto;

}

html,
body {
    scroll-behavior: smooth;
}

body::-webkit-scrollbar {
    width: 0.4em;
    border-radius: 50vh;
    cursor: pointer;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: rgb(249, 115, 22);
    outline: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hidden {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

div.disabled {
    pointer-events: none;
    opacity: 0.3;
}

div.blur {
    backdrop-filter: saturate(10%) blur(20px);    
}

body.modal-open {
    overflow: hidden;
    position: fixed;
}

@tailwind base;
@tailwind components;
@tailwind utilities;