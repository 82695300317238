.ticket .td,
.ticket th,
.ticket tr,
.ticket table {
    border-top: 1px solid black;
    border-collapse: collapse;
}

.ticket td.description,
.ticket th.description {
    width: 75px;
    max-width: 75px;
}

.ticket td.quantity,
.ticket th.quantity {
    width: 40px;
    max-width: 40px;
    word-break: break-all;
}

.ticket td.price,
.ticket th.price {
    width: 40px;
    max-width: 40px;
    word-break: break-all;
}

.ticket .centered {
    text-align: center;
    align-content: center;
}

.ticket {
    width: 155px;
    max-width: 155px;
    text-align: center;
    font-size: 12px;
    font-family: 'Times New Roman';
}

.ticket .logo {
    max-width: 60%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.ticket .imageQr {
    max-width: inherit;
    width: inherit;
    border: 1px solid #555;
    border-radius: 20%;
}

.ticket .header {
    font-size: 1.3em;
    font-weight: bold;
    text-transform: uppercase;
}