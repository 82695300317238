/* The switch - the box around the slider */
.settings .switch {
    position: relative;
    float: right;
    width: 60px;
    height: 34px;
    margin-left: auto; 
    margin-right: 0;
  }
  
  /* Hide default HTML checkbox */
  .settings .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .settings .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .settings .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .settings input:checked + .slider {
    background-color: #EA580C;
  }
  
  .settings input:focus + .slider {
    box-shadow: 0 0 1px #EA580C;
  }
  
  .settings input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .settings .slider.round {
    border-radius: 34px;
  }
  
  .settings .slider.round:before {
    border-radius: 50%;
  }